<script setup lang="ts">
  const pageInfoStore = usePageInfoStore();
  const { currentClinic } = storeToRefs(useClinicsStore());

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const showModal = ref(false);
  const showMap = ref(false);
  const showPhotos = ref(false);
  const showMobileModalGallery = ref(false);

  const yandexMap = computed(() => {
    const rawMap = currentClinic.value?.ymap;
    if (!rawMap) {
      return '';
    }
    const regex = /src="([^"]+)"/;
    const match = rawMap.match(regex);

    if (match && match[1]) {
      return match[1];
    }
    return '';
  });

  const photos = computed(() => {
    const clinic = currentClinic.value;
    if (!clinic || !clinic.photos) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (clinic.photos as any)['0x0'] as { image: string }[];
  });

  // говнокод
  const photosNormalize = computed(() => {
    const items = photos.value.filter((photo) => photo.image);
    const normalize = items.map((i) => i.image);
    return normalize;
  });

  const toggleMap = () => {
    showMap.value = !showMap.value;
    if (showMap.value) {
      document.documentElement.style.overflowY = 'hidden';
      return;
    }
    document.documentElement.style.overflowY = 'initial';
  };

  const clickOnPhoto = () => {
    if (isMobile.value) {
      showMobileModalGallery.value = true;
    } else {
      showPhotos.value = true;
    }
  };
</script>

<template>
  <div class="clinic-info-full-container">
    <div class="clinic-info-full-top">
      <h1 class="clinic-info-full-title" itemprop="name">
        Ист Клиника {{ clinicGetAddressInAdpositionalCase(currentClinic?.node_address) }}
      </h1>
      <div class="info-container">
        <div class="info-item order-1">
          <div class="icon-container">
            <IconHomeSmile class="icon" filled />
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span class="info-text" itemprop="address" v-html="currentClinic?.address"></span>
        </div>
        <div class="info-item order-4">
          <div class="icon-container">
            <IconDisabled class="icon" filled />
          </div>
          <span class="info-text">{{ Boolean(currentClinic?.clinic_pandus) ? 'Пандус есть':'Пандуса нет' }}</span>
        </div>
        <div class="info-item text-truncate order-2">
          <div class="icon-container">
            <IconClinicMetro class="icon" filled />
          </div>
          <ClinicCircleColor :color="currentClinic?.node_color" />
          <div class="d-flex" style="flex-wrap: wrap;">
            <div style="flex: 1">
              <span class="info-text" style="margin: 0px 4px">{{ currentClinic?.node_address }}</span>
            </div>
            <div style="flex: 1">
              <IconDirectionsWalk class="icon-walk" filled />
              <span class="distance">{{ clinicGetTextSecondary(currentClinic?.way, currentClinic?.way_time) }}</span>
            </div>
          </div>
        </div>
        <div class="info-item order-5">
          <div class="icon-container">
            <IconParking style="font-size: 18px;" class="icon" filled />
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-if="currentClinic?.clinic_parking" class="info-text" v-html="currentClinic?.clinic_parking"></span>
          <span v-else class="info-text">Нет информации</span>
        </div>
        <div class="info-item order-3">
          <div class="icon-container">
            <IconClock class="icon" filled />
          </div>
          <span class="info-text">{{ currentClinic?.clinic_hours }}</span>
        </div>
      </div>
      <div class="photos-container">
        <ScrollCarousel
          item-gap="8px"
          :show-arrows="true"
          :item-width="160"
          :arrows-pos="`0px`"
        >
          <div class="carousel-yandex-map" style="position: relative;" @click="toggleMap">
            <img
              v-if="currentClinic?.id === 1"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-1.webp"
              draggable="false"
            >
            <img
              v-else-if="currentClinic?.id === 2"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-2.webp"
              draggable="false"
            >
            <img
              v-else-if="currentClinic?.id === 3"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-3.webp"
              draggable="false"
            >
            <img
              v-else-if="currentClinic?.id === 6"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-6.webp"
              draggable="false"
            >
            <img
              v-else-if="currentClinic?.id === 18"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-18.webp"
              draggable="false"
            >
            <img
              v-else-if="currentClinic?.id === 23"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-23.webp"
              draggable="false"
            >
            <img
              v-else-if="currentClinic?.id === 45"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-45.webp"
              draggable="false"
            >
            <img
              v-else-if="currentClinic?.id === 81"
              class="carousel-yandex-map"
              loading="lazy"
              src="/assets/img/map/ymap-81.webp"
              draggable="false"
            >
            <div class="white-circle clinic-gallery-map-button">
              <IconMapOpen style="width: 20px;height: 20px; margin: 0px;" />
            </div>
          </div>
          <img
            v-for="photo in photos"
            :key="photo.image"
            class="carousel-photo"
            :src="photo.image"
            alt=""
            draggable="false"
            @click="clickOnPhoto"
          >
        </ScrollCarousel>
      </div>
    </div>
    <div class="clinic-info-full-bottom">
      <button type="button" class="clinic-info-button" @click="showModal = true">
        Как добраться
      </button>
      <NuxtLink target="_blank" external :href="pageInfoStore.getLinkPhone" class="whats-app-container">
        <IconPhoneHeader filled :font-controlled="false" class="icon-whats-app" />
      </NuxtLink>
      <NuxtLink target="_blank" external :href="pageInfoStore.getWhatsAppPhone" rel="nofollow" class="whats-app-container">
        <IconWhatsApp filled :font-controlled="false" class="icon-whats-app" />
      </NuxtLink>
    </div>
    <ClinicPageModalLocation
      v-model="showModal"
      :modal-content="currentClinic?.info_block ?? []"
      :google-link="currentClinic?.google_link ?? ''"
      :lat="currentClinic?.lat ?? 0"
      :lon="currentClinic?.lon ?? 0"
    />
    <Teleport v-if="showMap" to="body">
      <div class="map-overlay" @click.stop="toggleMap">
        <div class="map-container">
          <div class="map">
            <component
              :is="'script'"
              :src="yandexMap"
              async
            />
            <div class="close-map-button">
              Закрыть
            </div>
          </div>
        </div>
      </div>
    </Teleport>
    <Teleport v-if="showPhotos" to="body">
      <div class="modal">
        <LazyClinicPagePhotoCarousel :photos="photos" />
      </div>
      <div class="overlay" @click.stop="showPhotos = false"></div>
    </Teleport>
    <ModalGallery
      :visible="showMobileModalGallery"
      :items="photosNormalize"
      :doctor="null"
      @modal-gallery:close="showMobileModalGallery = false"
    />
  </div>
</template>

<style scoped lang="scss">
.clinic-info-full-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.clinic-info-full-top {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-areas:
    "title photos"
    "info photos"
    "info .";
  @media (max-width: 1023px) {
    grid-template-areas:
    "title title"
    "photos photos"
    "info info"
  }
}
.clinic-info-full-title {
  grid-area: title;
  color: #262633;
  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  padding-bottom: 24px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.info-container {
  grid-area: info;
  grid-auto-flow: row;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: 16px;
  column-gap: 40px;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
  }
}
.info-item {
  display: flex;
  align-items: center;
}
.text-truncate {
  text-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: #F2F3F6;
  border-radius: 999px;
  margin-right: 12px;
}
.icon {
  font-size: 24px;
  min-width: 24px;
  margin: 0px;
}
.icon-walk {
  font-size: 14px;
  min-width: 14px;
  margin: 0px;
}
.info-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.distance {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.order-1 {
  @media (max-width: 1023px) {
    order: 1;
  }
}
.order-2 {
  @media (max-width: 1023px) {
    order: 2;
  }
}
.order-3 {
  @media (max-width: 1023px) {
    order: 3;
  }
}
.order-4 {
  @media (max-width: 1023px) {
    order: 4;
  }
}
.order-5 {
  @media (max-width: 1023px) {
    order: 5;
  }
}
.photos-container {
  grid-area: photos;
  @media (min-width: 1023px) {
    padding-left: 102px;
  }
}
.clinic-info-full-bottom {
  display: flex;
  gap: 8px;
}
.clinic-info-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 56px;
  padding: 14px 16px;
  border-radius: 15px;
  background-color: #2D7FF9;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.whats-app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  padding: 12px;
  border-radius: 16px;
  background-color: #F5F6F9;
  cursor: pointer;
  &:hover {
    background-color: #EDEEF3;
  }
}
.icon-whats-app {
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin: 0;
}
.carousel-photo {
  min-width: 160px;
  height: 140px;
  border-radius: 24px;
  object-fit: cover;
  cursor: pointer;
}
.carousel-yandex-map {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 24px;
  cursor: pointer;
}
.clinic-gallery-map-button {
  position: absolute;
  top: 12px;
  right: 12px;
}
.map-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(0, 0, 0, 0.5);
}
.map-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 600px;
  height: 600px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    transform: none;
    left: 0;
    top: 0;
  }
}
.map {
  position: relative;
  width: 600px;
  height: 600px;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
}
.close-map-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2D7FF9;
  border-radius: 15px;
  color: white;
  text-align: center;
  padding: 0px 20px;
  width: 120px;
  height: 40px;
  bottom: 50px;
  left: 78%;
  cursor: pointer;
  @media (max-width: 767px) {
    left: calc(95% - 120px);
    bottom: 60px;
  }
}
.modal {
  z-index: 102;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 713px;
  height: 435px;
}
.overlay {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
